import { useEffect, useState } from 'react';
import { useGlobal } from '../../../context/GlobalContext';
import { ReactComponent as IconPending } from '../../../assets/svg/icon-pending.svg';
import { doc, onSnapshot, query } from 'firebase/firestore';
import { db, functions } from '../../../firebase.config';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../inc/helpers';
import { httpsCallable } from 'firebase/functions';
import useQuery from '../../../hooks/useQuery';

function ProcessingPayment({ type }) {
    const queryParams = useQuery();
    const { engagementId } = useParams();
    const navigate = useNavigate();
    const { dispatch } = useGlobal();
    const [engagementVerified, setEngagementVerified] = useState(false);
    const [redirected, setRedirected] = useState(false);

    // Set title
    useEffect(() => {
        document.title = 'Processing... - QuicklyHire';
        dispatch({
            type: 'SET_PAGE_TITLE',
            payload: `Processing...`,
        });
        window.scrollTo(0, 0);
    }, [dispatch]);

    // Verify engagement
    useEffect(() => {
        const verifyEngagement = async () => {
            try {
                if (engagementVerified) return;

                const clientAction = httpsCallable(functions, 'clientAction');

                // Get query payment_intent
                const paymentIntentId = queryParams.get('payment_intent');

                const { data } = await clientAction({
                    type: 'verifyProcessingPayment',
                    engagementId,
                    processingType: type,
                    paymentIntentId,
                });

                if (data.status === 'error') {
                    toast.error(data.message);
                    if (data.redirect) navigate(data.redirect);
                    else navigate('/team');

                    return;
                }

                if (data.status === 'success') {
                    if (data.redirect) navigate(data.redirect);
                    else setEngagementVerified(true);

                    return;
                }
            } catch (error) {
                console.error(error);
                toast.error(getErrorMessage(error));
                navigate('/team');
            }
        };

        verifyEngagement();
    }, [engagementId, navigate, engagementVerified, type, queryParams]);

    useEffect(() => {
        let unsubscribe = null;

        if (!engagementVerified) return;

        unsubscribe = onSnapshot(
            query(doc(db, 'engagements', engagementId)),
            async doc => {
                // Check if doc exists
                if (!doc.exists()) return;

                const engagement = doc.data();

                if (type === 'payment') {
                    // If engagement is switched to pending activation, then redirect to payment-success
                    if (
                        engagement.status === 'pending' &&
                        engagement.pending === 'activation'
                    ) {
                        navigate(`/payment-success/${engagementId}`);
                        return;
                    }

                    // If engagement is switched to pending payment, then redirect to team page
                    if (
                        engagement.status === 'pending' &&
                        engagement.pending === 'payment'
                    ) {
                        toast.error('Payment failed. Please try again.');
                        navigate(`/team/checkout/${engagementId}`);
                        return;
                    }
                }

                if (type === 'upgrade') {
                    if (
                        engagement.status === 'active' &&
                        engagement.pending === 'upgrade' &&
                        engagement.upgrade &&
                        !engagement.upgrade.isPaid
                    ) {
                        toast.error('Payment failed. Please try again.');
                        navigate(`/team/upgrade-checkout/${engagementId}`);
                        return;
                    }

                    if (
                        engagement.status === 'active' &&
                        engagement.pending === 'upgrade' &&
                        engagement.upgrade &&
                        engagement.upgrade.isPaid
                    ) {
                        navigate(`/upgrade-payment-success/${engagementId}`);
                        return;
                    }
                }
            }
        );

        return () => unsubscribe();
    }, [engagementVerified, engagementId, navigate, type]);

    return (
        <div className="p-4 relative">
            <div className="max-w-2xl mx-auto bg-black-alt text-white text-base text-center p-8 lg:p-12 rounded-lg">
                <div className="relative">
                    <div className="w-20 h-20 inline-flex justify-center items-center mx-auto mb-4 bg-white rounded-full">
                        <IconPending className="w-10 h-10 opacity-80 animate-sandclock" />
                    </div>
                    <h3 className="text-white mb-0">
                        Processing your payment! Please wait...
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default ProcessingPayment;
