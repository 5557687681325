import { createContext, useContext, useReducer } from 'react';
import globalReducer from './GlobalReducer';
import { useQuery } from '../components/Hooks';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const queryURL = useQuery();

    const initialState = {
        pageTitle: '',
        backLink: '',
        currentPortfolio: null,
        currentFile: null,
        currentPro: null,
        counts: {},
        filters: {
            engagements: {
                status: ['active', 'pending'],
                archived: false,
                positions: [],
                search: '',
                manager: '',
                pending: [],
                hasScheduledUpdates: false,
            },
            pros: {
                status: ['active'],
                positions: [],
                skills: [],
                sortby: 'availability',
                search: '',
                canBeHiredFulltime: false,
            },
        },
        engagementsView: 'table',
        userAvatar: '',
        refetch: 0,
        navNotification: {},
        generatedBio: null,
        generatedHeadline: null,
        generatedShortBio: null,
        loadingBioGeneration: false,
        resumeURL: null,
        resumeFile: null,
        shouldFetchClients: false,
    };
    const [state, dispatch] = useReducer(globalReducer, initialState);

    return (
        <GlobalContext.Provider
            value={{
                ...state,
                dispatch,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export const useGlobal = () => useContext(GlobalContext);

export default GlobalContext;
