function Container({ children, className = '', padding = true, screenWidth = false, border = true, background = true, ...props }) {
    return (
        <div
            className={`relative w-full ${!screenWidth && 'max-w-[1300px]'} ${border && 'lg:border lg:border-solid lg:rounded-xl lg:shadow-sm lg:border-gray-300'} ${background && 'bg-white'} mb-10 ${
                padding ? 'px-5 pb-5 pt-0 lg:pt-5' : ''
            } ${className}`}
            {...props}
        >
            {children}
        </div>
    );
}

export default Container;
