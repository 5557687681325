import { useField, useFormikContext } from 'formik';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';
import { inputClasses } from '../../inc/utils';
import { useEffect } from 'react';
import CharsLimit from './parts/CharsLimit';

export const TextareaInput = ({
    label,
    description = '',
    info = '',
    className = '',
    inputClassName = '',
    charsLimit = null,
    ...props
}) => {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    // Chars limit
    useEffect(() => {
        if (field.value && charsLimit && field.value.length > charsLimit) {
            setFieldValue(field.name, field.value.slice(0, charsLimit));
        }
    }, [charsLimit, field.value, setFieldValue, field.name]);
    return (
        <InputContainer className={className}>
            {label ? (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            ) : (
                ''
            )}
            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            {charsLimit && (
                <CharsLimit value={field.value} charsLimit={charsLimit} />
            )}
            <textarea
                {...field}
                {...props}
                className={`${inputClasses} ${inputClassName} min-h-[100px] ${
                    !label ? 'mt-3' : ''
                }`}
            >
                {meta.value}
            </textarea>
            {info ? (
                <InputInfo>
                    <i>{info}</i>
                </InputInfo>
            ) : (
                ''
            )}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default TextareaInput;
