import {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
} from 'react';
import { auth, functions } from '../firebase.config';
import { onAuthStateChanged } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

const AuthStatusContext = createContext();

export const AuthStatusProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [checkingStatus, setCheckingStatus] = useState(true);
    const [user, setUser] = useState(null);
    const [claims, setClaims] = useState(null);

    // Function to refresh user claims
    const refreshUserClaims = useCallback(async user => {
        if (user) {
            const idTokenResult = await user.getIdTokenResult(true);
            setClaims(prev => ({ ...prev, ...idTokenResult.claims }));

            if (idTokenResult.claims.role) {
                setIsLoggedIn(true);
            }
        }
    }, []);

    const syncTimezone = useCallback(async user => {
        try {
            const idTokenResult = await user.getIdTokenResult(true);
            if (!idTokenResult.claims.timezone) {
                // Get device timezone
                const now = new Date();
                const offsetMinutes = now.getTimezoneOffset(); // Get timezone offset in minutes
                const offsetHours = -offsetMinutes / 60; // Convert minutes to hours

                // Convert offset to formatted string (e.g., "+02:00" or "-05:30")
                const formattedOffset =
                    (offsetHours >= 0 ? '+' : '-') +
                    String(Math.floor(Math.abs(offsetHours))).padStart(2, '0') +
                    ':' +
                    String(Math.abs(offsetHours % 1) * 60).padStart(2, '0');

                setClaims(prev => ({
                    ...prev,
                    timezone: formattedOffset,
                }));

                const authAction = httpsCallable(functions, 'authAction');

                const { data: response } = await authAction({
                    type: 'syncTimezone',
                    timezone: formattedOffset,
                });

                // Update id token
                if (response.success) {
                    await refreshUserClaims(user);
                    console.log('Timezone synced successfully');
                } else {
                    console.error('Failed to sync timezone');
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, user => {
            const doAsync = async () => {
                try {
                    if (user) {
                        setUser(user);
                        await refreshUserClaims(user);
                        await syncTimezone(user);
                    } else {
                        setIsLoggedIn(false);
                        setUser(null);
                        setClaims(null);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setCheckingStatus(false);
                }
            };
            doAsync();
        });
        return () => {
            unsub();
        };
    }, [refreshUserClaims, syncTimezone]);

    return (
        <AuthStatusContext.Provider
            value={{
                isLoggedIn,
                checkingStatus,
                user,
                claims,
                refreshUserClaims,
            }}
        >
            {children}
        </AuthStatusContext.Provider>
    );
};

export const useAuthStatus = () => useContext(AuthStatusContext);

export default AuthStatusContext;
